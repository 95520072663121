import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'folder/Inbox',
    pathMatch: 'full'
  },
  {
    path: 'folder/:id',
    loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'home-partner',
    loadChildren: () => import('./home-partner/home-partner.module').then( m => m.HomePartnerPageModule)
  },
  {
    path: 'student-register',
    loadChildren: () => import('./student-register/student-register.module').then( m => m.StudentRegisterPageModule)
  },
  {
    path: 'approved-students',
    loadChildren: () => import('./approved-students/approved-students.module').then( m => m.ApprovedStudentsPageModule)
  },
  {
    path: 'disapprove-students',
    loadChildren: () => import('./disapprove-students/disapprove-students.module').then( m => m.DisapproveStudentsPageModule)
  },
  {
    path: 'test-results',
    loadChildren: () => import('./test-results/test-results.module').then( m => m.TestResultsPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'edit',
    loadChildren: () => import('./student/edit/edit.module').then( m => m.EditPageModule)
  },
  {
    path: 'generate-admitcard',
    loadChildren: () => import('./student/generate-admitcard/generate-admitcard.module').then( m => m.GenerateAdmitcardPageModule)
  },
  {
    path: 'view-details',
    loadChildren: () => import('./student/view-details/view-details.module').then( m => m.ViewDetailsPageModule)
  },
  {
    path: 'new-register',
    loadChildren: () => import('./student/new-register/new-register.module').then( m => m.NewRegisterPageModule)
  },
  {
    path: 'add-materials',
    loadChildren: () => import('./add-materials/add-materials.module').then( m => m.AddMaterialsPageModule)
  },
  {
    path: 'print-admitcard',
    loadChildren: () => import('./student/print-admitcard/print-admitcard.module').then( m => m.PrintAdmitcardPageModule)
  },
  {
    path: 'print',
    loadChildren: () => import('./student/print/print.module').then( m => m.PrintPageModule)
  },
  {
    path: 'print-icard',
    loadChildren: () => import('./student/print-icard/print-icard.module').then( m => m.PrintIcardPageModule)
  },
  {
    path: 'certificate-marksheet',
    loadChildren: () => import('./student/certificate-marksheet/certificate-marksheet.module').then( m => m.CertificateMarksheetPageModule)
  },  {
    path: 'student-exam-details',
    loadChildren: () => import('./student-exam-details/student-exam-details.module').then( m => m.StudentExamDetailsPageModule)
  },
  {
    path: 'exam-result-detail',
    loadChildren: () => import('./exam-result-detail/exam-result-detail.module').then( m => m.ExamResultDetailPageModule)
  },

 
 
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
